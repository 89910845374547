<template>
	<edit-template class="add-buildings" style="padding-top: 64rem" @confirm="saveData" @cancel="cancelData" :cancelRemind="false" confirmText="保存" cancelText="取消">

		<el-form ref="form" :model="form" label-width="80rem">
			<el-form-item label="所属校区" required="">
				<el-select :popper-append-to-body="false" v-model="form.school_id" placeholder="请选择">
					<el-option v-for="(item, index) in userInfo.school_arr" :key="index" :label="item" :value="index"></el-option>
				</el-select>
			</el-form-item>

			<el-form-item label="楼宇名称" required="">
				<el-input v-model="form.dimicile_buliding_name" placeholder="请输入"></el-input>
			</el-form-item>

			<el-form-item label="楼宇用途" required="">
				<el-select :popper-append-to-body="false" v-model="form.usage" multiple="" @change="clickData" placeholder="请选择">
					<el-option v-for="item in buildinglist" :value-key="item" :key="item" :label="item" :value="item"></el-option>
				</el-select>
			</el-form-item>
			<el-form-item label="最低楼层" required="">
				<el-input v-model="form.floor_min" placeholder="请输入"></el-input>
			</el-form-item>
			<el-form-item label="最高楼层" required="">
				<el-input v-model="form.floor_max" placeholder="请输入"></el-input>
			</el-form-item>
			<el-form-item label="房间数">
				<el-input v-model="form.num" placeholder="请输入"></el-input>
			</el-form-item>
		</el-form>


</edit-template>
</template>

<script>
import { AddBuildings, buildingsUsage } from '@/api/building'
import { mapGetters } from 'vuex'

export default {
	_config:{"route":{"path":"add","meta":{"title":"新增"}}},
  data() {
		return {
			form: {
				school_id: null,
				dimicile_buliding_name: null,
				usage: null,
				floor_min: null,
				floor_max: null,
				num: null
			},
			buildinglist: {},
			tab: true
		}
	},
	computed: {
		...mapGetters(['userInfo'])
	},
	methods: {
		async getData() {
			let res = await buildingsUsage()
			this.buildinglist = res.data.data
		},

		clickData(val) {
			this.form.usage = val
		},

		async saveData() {
			let usageid = []
			for (let i in this.buildinglist) {
				this.form.usage.forEach(item => {
					if (this.buildinglist[i] == item) {
						usageid.push(i)
					}
				})
			}

			let res = await AddBuildings({
				school_id: this.form.school_id,
				dimicile_buliding_name: this.form.dimicile_buliding_name,
				usage: usageid.join(','),
				floor_min: this.form.floor_min,
				floor_max: this.form.floor_max,
				num: this.form.num || 0
			})
			let { error } = res.data
			if (error.errorCode == 1) {
				this.$message({
					type: 'error',
					message: error.errorUserMsg
				})
			} else if (error.errorCode == 0) {
				this.$message({
					type: 'success',
					message: '添加成功'
				})
				// this.$router.push('/logistics/logisticsInfo/building/list')
				this.$store.commit('setPage', 1)
				this.$router.back()
			}
		},
		cancelData() {
			this.$confirm('是否取消新增', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning'
			}).then(() => {
				// this.$message({
				// 	type: 'success',
				// 	message: '新增已取消'
				// })
				this.$router.back()
			})
		}
	},
	// mounted(){

	// },
	created() {
		this.getData()
	}
}
</script>

<style lang="scss" scoped>
.add-buildings {
	box-sizing: border-box;

	padding-left: 75rem;
	padding-top: 40rem;
	color: #666;
}

//.el-button {
//	width: 115rem;
//	height: 40rem;
//	margin-left: 40rem;
//}

.el-form-item {
	margin-top: 20rem;
}

.adressinput {
	width: 568rem;
	margin-left: 120rem;
}

// ::v-deep .el-form-item--small .el-form-item__label{
// width: 120rem !important;
// }
.el-button--primary {
	background-color: #1d2088;
}

.lowerbtn {
	background-color: rgba(204, 204, 204, 0.39);
	// width: 60rem;
	height: 40rem;
	text-align: center;
	line-height: 20rem;
	// line-height: 40rem;
	color: #333333;
	border: 0;
	box-sizing: border-box;
}
</style>
